<template>
  <keep-alive :exclude="['HistoryPropertyList', 'HistoryControlsList']">
    <component
      :is="currentView.component"
      :key="stackKey"
      v-bind="currentView.props"
      @stack:back="handleBack"
      @stack:close="$emit('stack:close')"
    />
  </keep-alive>
</template>

<script>
import useHistoryStack from '@/compositions/history-stack';
import Vue from 'vue';

export default Vue.extend({
  name: 'HistoryStack',
  props: {
    defaultViewComponent: {
      type: [String, Object, Function],
      default: ''
    }
  },
  setup(props, { root, attrs }) {
    const { currentView, getPrevRoute, stackKey } = useHistoryStack({
      component: props.defaultViewComponent,
      props: attrs
    });

    const handleBack = () => {
      root.$router.push(getPrevRoute());
    };

    return {
      currentView,
      handleBack,
      stackKey
    };
  }
});
</script>

<style></style>
